import { configureStore } from "@reduxjs/toolkit";
import { boTheBotApi } from "./baseApi";
import OrdersSlice from "./order/OrderSlice";

export const store = configureStore({
    reducer: {
        [boTheBotApi.reducerPath]: boTheBotApi.reducer,
        store: OrdersSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(boTheBotApi.middleware)
});
