import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import warn from "../../assets/images/warn.png";
import "../../assets/css/defaultError.css";
export const DefaultError = ({ open, onClick, heading, message }) => {
    const sx = {
        "& .MuiDialog-container": {
            alignItems: "flex-start"
        }
    };
    return (
        <Dialog
            hideBackdrop={true}
            open={open}
            sx={sx}
            PaperProps={{
                style: {
                    backgroundColor: "rgba(209, 54, 54, 1)",
                    boxShadow: "none"
                }
            }}>
            <div className="default-error-container">
                <div>
                    <img className="warn" src={warn} alt="warn" />
                </div>
                <div className="details">
                    <p>{heading}</p>
                    <p>{message}</p>
                </div>
                <div className="icon" onClick={onClick}>
                    <CloseIcon className="close-icon" />
                </div>
            </div>
        </Dialog>
    );
};
