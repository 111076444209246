import { useEffect } from "react";
import {
    useBoTheBotAccountLocationMutation,
    useGetSavedLocationsMutation,
    usePasswordVerificationMutation,
    useUpdateCustomerCardInfoMutation,
    useUpdateUserInfoMutation,
    useRemoveLocationIndexMutation,
    useUpdateBothebotAccountMutation
} from "../store/user/UserApi";

export const useUser = ({ onSuccess, onError, onVerified, onVerifiedError, onUpdated, onRemove }) => {
    const [updateUserInfo, { isLoading, isSuccess, isError, data }] = useUpdateUserInfoMutation();
    const [
        updateLocation,
        { isLoading: updateLoading, isSuccess: updateSuccess, isError: updateError, data: updatedData }
    ] = useBoTheBotAccountLocationMutation();
    const [
        removeLocation,
        { isLoading: removeLoading, isSuccess: removeSuccess, isError: removeError, data: removeData }
    ] = useRemoveLocationIndexMutation();
    const [
        getLocations,
        { isLoading: locationsLoading, isSuccess: locationsSuccess, isError: locationsError, data: locationsData }
    ] = useGetSavedLocationsMutation();

    const [
        updateCustomerCardInfo,
        {
            isLoading: customerCardLoading,
            isSuccess: customerCardSuccess,
            isError: customerCardError,
            data: customerCardData
        }
    ] = useUpdateCustomerCardInfoMutation();
    const [
        passwordVerification,
        {
            isLoading: passVerificationLoading,
            isSuccess: passVerificationSuccess,
            isError: passVerificationError,
            data: passVerificationData
        }
    ] = usePasswordVerificationMutation();

    const [
        updateBOthebotAccount,
        {
            isLoading: updateBOthebotAccountLoading,
            isSuccess: updateBOthebotAccountSuccess,
            isError: updateBOthebotAccountError,
            data: updateBOthebotAccountData
        }
    ] = useUpdateBothebotAccountMutation();

    useEffect(() => {
        if (locationsLoading) {
            return;
        }
        if (locationsSuccess) {
            onSuccess?.();
        } else if (locationsError) {
            onError?.();
        }
    }, [locationsLoading, locationsSuccess, locationsError, locationsData]);
    useEffect(() => {
        if (updateBOthebotAccountLoading) {
            return;
        }
        if (updateBOthebotAccountSuccess) {
            onSuccess?.();
        } else if (updateBOthebotAccountError) {
            onError?.();
        }
    }, [
        updateBOthebotAccountLoading,
        updateBOthebotAccountSuccess,
        updateBOthebotAccountError,
        updateBOthebotAccountData
    ]);
    useEffect(() => {
        if (updateLoading) {
            return;
        }
        if (updateSuccess) {
            onUpdated?.();
        } else if (updateError) {
            onError?.();
        }
    }, [updateLoading, updateSuccess, updateError, updatedData]);
    useEffect(() => {
        if (passVerificationLoading) {
            return;
        }
        if (passVerificationSuccess && passVerificationData?.status === 200) {
            onVerified?.();
        } else if (passVerificationData?.status === 400 || passVerificationError) {
            onVerifiedError?.();
        }
    }, [passVerificationLoading, passVerificationSuccess, customerCardError, passVerificationData]);

    useEffect(() => {
        if (customerCardSuccess && isSuccess) {
            onSuccess?.();
        } else if (customerCardError) {
            onError?.();
        }
    }, [customerCardLoading, customerCardSuccess, customerCardError, isSuccess]);

    useEffect(() => {
        if (removeSuccess) {
            onRemove?.();
        } else if (removeError) {
            onError?.();
        }
    }, [removeLoading, removeSuccess, removeError, removeData]);

    useEffect(() => {
        if (isSuccess) {
            //   onSuccess?.();
        } else if (isError) {
            onError?.();
        }
    }, [isLoading, isSuccess, isError]);

    const handleUpdateUserInfo = payload => {
        updateUserInfo(payload);
    };
    const handleUpdateCustomerCardInfo = payload => {
        updateCustomerCardInfo(payload);
    };
    const handlePassVerification = payload => {
        passwordVerification(payload);
    };
    const handleAddLocation = payload => {
        updateLocation(payload);
    };
    const handleGetLocations = payload => {
        getLocations(payload);
    };
    const handleRemoveLocations = payload => {
        removeLocation(payload);
    };
    const handleUpdateBothebotAccount = payload => {
        updateBOthebotAccount(payload);
    };

    return {
        isLoading,
        handleUpdateUserInfo,
        isSuccess,
        data,
        isError,
        handleUpdateCustomerCardInfo,
        handlePassVerification,
        handleAddLocation,
        updateLoading,
        handleGetLocations,
        locationsData,
        locationsLoading,
        handleRemoveLocations,
        removeLoading,
        handleUpdateBothebotAccount,
        updateBOthebotAccountLoading
    };
};
