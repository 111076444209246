import React, { useState } from "react";
const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
    /* #region Main App */
    const [showShippingAddressPopup, setShowShippingAddressPopup] = useState(false); // Shipping Address Popup
    const [shippingAddressPopupData, setShippingAddressPopupData] = useState({}); // Shipping Address Popup Data
    /* #endregion */

    return (
        <AppContext.Provider
            value={{
                showShippingAddressPopup,
                setShowShippingAddressPopup,
                shippingAddressPopupData,
                setShippingAddressPopupData
            }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
