const CREATE_ORDER = "createNewOrder";
const HANDLE_CARD_PAYMENT = "createStripePayment";
const HANDLE_PAYMENT_TRANSFERS = "handleTransfers";
const UPDATE_USER_INFO = "updateUserInfo";
const UPDATE_CUSTOMER_CARD_INFO = "updateCustomerCardInfo";
const PASSWORD_VERIFICATION = "WebsitePasswordValidation";
const GET_ORDERS = "getOrdersData";
const UPDATE_ORDER_TO_ARCHIVED = "updateOrderToHistory";
const GET_ORDER_DETAILS = "getOrderDetails";
const GET_PRODUCT_INFO = "getProductInfo";
const GET_ALL_BO_PICS = "getAllBOpics";
const BO_THE_ACC_LOCATION = "BOtheBotAccountLocation";
const GET_SAVED_LOCATIONS = "getSavedLocations";
const GET_MY_STUFF_DATA = "getMyStuffData";
const REMOVE_LOCATION_INDEX = "removeLocationIndex";
const UPDATE_BOTHEBOT_ACCOUNT = "updateBOthebotAccount";
const HANDLE_DELETE_ACCOUNT = "deleteAccount";
const HANDLE_FEEDBACK = "CreateSaleFeedback";
const HANDLE_COMMON_API = "callCommonAPI";

export {
    CREATE_ORDER,
    UPDATE_USER_INFO,
    UPDATE_CUSTOMER_CARD_INFO,
    PASSWORD_VERIFICATION,
    HANDLE_CARD_PAYMENT,
    GET_ORDERS,
    UPDATE_ORDER_TO_ARCHIVED,
    GET_ORDER_DETAILS,
    GET_PRODUCT_INFO,
    GET_ALL_BO_PICS,
    BO_THE_ACC_LOCATION,
    GET_SAVED_LOCATIONS,
    GET_MY_STUFF_DATA,
    REMOVE_LOCATION_INDEX,
    HANDLE_PAYMENT_TRANSFERS,
    UPDATE_BOTHEBOT_ACCOUNT,
    HANDLE_DELETE_ACCOUNT,
    HANDLE_FEEDBACK,
    HANDLE_COMMON_API
};
