import LoaderIcon from "./../../assets/images/pro.svg";

export default function preLoader() {
    return (
        <div style={{ background: "black" }}>
            <div className="pre-loader-img pre-inrollment pre-loader">
                <div className="maaintain-height">
                    <img
                        className="loader-boicon"
                        src={LoaderIcon}
                        alt="loader"
                        style={{
                            width: "60px",
                            height: "60px"
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
