import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./../../assets/css/static/css/dark/style-dark.css";
import axios from "axios";
import { useUser } from "../../hooks/useUser";
import BoTagLogo from "./../../assets/images/newBotagLogo.png";
import toast, { Toaster } from "react-hot-toast";

export default function Main() {
    const { processId } = useParams();
    const homeRef = useRef();
    const serviceRef = useRef();
    const contactRef = useRef();
    const navigate = useNavigate();
    const { handlePassVerification } = useUser({
        onVerifiedError: () => {
            // navigate("/password");
            if (processId) {
                navigate(`/password/${processId}`);
            } else {
                navigate("/password");
            }
            localStorage.removeItem("boAuth");
            localStorage.removeItem("boAuthCode");
            setPassError(true);
            setTextError(true);
        },
        onVerified: () => {
            localStorage.setItem("boAuth", false);
            // navigate("/feed");
            localStorage.setItem("boAuthCode", password);
            if (processId) {
                navigate(`/${processId}`);
            } else {
                navigate("/feed");
            }
        }
    });
    useEffect(() => {
        if (localStorage.getItem("boAuth") === "false") {
            // navigate("/feed");
            //window.location.href = "/feed"
            if (processId) {
                navigate(`/${processId}`);
            } else {
                navigate("/feed");
            }
        } else {
            // navigate("/password");
            if (processId) {
                navigate(`/password/${processId}`);
            } else {
                navigate("/password");
            }
        }
        const script = document.getElementById("recaptcha-script");
        if (script) {
            script.remove();
        }

        const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    }, []);
    const toggleMenu = () => {
        document.getElementById("navbar-collapse-toggle").classList.add(["menu-open"]);
    };
    const closeMenu = () => {
        document.getElementById("navbar-collapse-toggle").classList.remove(["menu-open"]);
    };

    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState(false);
    const [textError, setTextError] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const handlePassword = e => {
        setPassError(false);
        setTextError(false);
        setPassword(e.target.value);
    };
    const handleValidation = () => {
        let formIsValid = true;
        if (!password) {
            formIsValid = false;
            setPassError(true);
        }
        return formIsValid;
    };
    const handleForm = async () => {
        if (handleValidation()) {
            setButtonDisable(true);
            if (process.env.REACT_APP_SHOW_PASSWORD_SCREEN == "1") {
                handlePassVerification({ password: password });
            }

            setButtonDisable(false);
            // await axios
            //   .post(
            //     process.env.REACT_APP_API_URL +
            //       "BoPasswordVerification?code=" +
            //       process.env.REACT_APP_API_CODE,
            //     { password: password }
            //   )
            //   .then((res) => {
            //     if (res.data.status === 200) {
            //       localStorage.setItem("boAuth", false);
            //       navigate("/feed");
            //       localStorage.setItem("boAuthCode", password);
            //       //window.location.href = "/feed"
            //     } else {
            //       setPassError(true);
            //       setTextError(true);
            //     }
            // setButtonDisable(false);
            //   });
        }
    };
    const handleKeypress = e => {
        if (e.charCode === 13 || e.keyCode === 13) {
            handleForm();
        }
    };
    return (
        <>
            <Toaster />
            <div className="mob-header dark" id="_header">
                <div className="d-flex align-items-center">
                    <div className="navbar-brand">
                        <div className="about-avatar">
                            <Link to="/">
                                <img src={BoTagLogo} title="" alt="" style={{ height: "50px", width: "50px" }} />
                            </Link>
                        </div>
                    </div>
                    <button className="toggler-menu" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
            <header className="header-left d-flex flex-column" id="navbar-collapse-toggle">
                <div className="close-icon" onClick={closeMenu}>
                    <button className="hl_menu_close">
                        <span></span>
                    </button>
                </div>
                <ul className="nav">
                    <li>
                        <Link className="nav-link" to="/">
                            <span>Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link className="nav-link" to="/#service">
                            <span>You will love it</span>
                        </Link>
                    </li>
                    <li>
                        <Link className="nav-link" to="/#contact">
                            <span>Become a first user</span>
                        </Link>
                    </li>
                    <li>
                        <Link className="nav-link active" to="/password">
                            <span>Product preview</span>
                        </Link>
                    </li>
                </ul>
                <div className="header-contact">
                    <label>Reach out:</label>
                    <Link to="mailto:info@bothebot.com">info@bothebot.com</Link>
                </div>
            </header>
            <main>
                <div className="passwordPageCom">
                    <div className="loginDivItem">
                        <div className="loginInput">
                            <input
                                type="password"
                                className={passError ? "form-control error" : "form-control"}
                                placeholder="Enter password"
                                onChange={handlePassword}
                                onKeyPress={handleKeypress}
                            />
                            {textError ? (
                                <span className="lgionErrorFields">
                                    Incorrect password. Calling 911 (just kidding, try again)
                                </span>
                            ) : (
                                ""
                            )}
                            <button
                                className="letsBoPassword"
                                type="submit"
                                onClick={handleForm}
                                disabled={buttonDisable}>
                                <span className="btnNames">LET'S BO!</span>
                                <i className="arrowmyin"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
