import {
    BO_THE_ACC_LOCATION,
    GET_SAVED_LOCATIONS,
    PASSWORD_VERIFICATION,
    UPDATE_CUSTOMER_CARD_INFO,
    UPDATE_USER_INFO,
    REMOVE_LOCATION_INDEX,
    UPDATE_BOTHEBOT_ACCOUNT
} from "../../api/Endpoints";
import { boTheBotApi } from "../baseApi";

export const UserApiEndpoints = boTheBotApi.injectEndpoints({
    endpoints: builder => ({
        updateUserInfo: builder.mutation({
            query: (body, id) => ({
                url: `${UPDATE_USER_INFO}?code=${process.env.REACT_APP_API_CODE}&data=${body}&id=${id}`,
                method: "POST"
            }),
            invalidatesTags: ["Order", "User"]
        }),
        updateCustomerCardInfo: builder.mutation({
            query: (body, id) => ({
                url: `${UPDATE_CUSTOMER_CARD_INFO}?code=${process.env.REACT_APP_API_CODE}&data=${body}&id=${id}`,
                method: "POST"
            }),
            invalidatesTags: ["Order", "User"]
        }),
        passwordVerification: builder.mutation({
            query: password => ({
                url: `${PASSWORD_VERIFICATION}?code=${process.env.REACT_APP_API_CODE}`,
                method: "POST",

                body: password
            }),
            invalidatesTags: ["Order", "User"]
        }),
        boTheBotAccountLocation: builder.mutation({
            query: body => ({
                url: `${BO_THE_ACC_LOCATION}?code=${process.env.REACT_APP_API_CODE}`,
                method: "POST",

                body
            }),
            invalidatesTags: ["Order", "User"]
        }),
        getSavedLocations: builder.mutation({
            query: body => ({
                url: `${GET_SAVED_LOCATIONS}?code=${process.env.REACT_APP_API_CODE}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Order", "User"]
        }),
        removeLocationIndex: builder.mutation({
            query: body => ({
                url: `${REMOVE_LOCATION_INDEX}?code=${process.env.REACT_APP_API_CODE}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Order", "User"]
        }),
        updateBothebotAccount: builder.mutation({
            query: body => ({
                url: `${UPDATE_BOTHEBOT_ACCOUNT}?code=${process.env.REACT_APP_API_CODE}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Order", "User"]
        })
    })
});

export const {
    useUpdateUserInfoMutation,
    useUpdateCustomerCardInfoMutation,
    usePasswordVerificationMutation,
    useBoTheBotAccountLocationMutation,
    useGetSavedLocationsMutation,
    useRemoveLocationIndexMutation,
    useUpdateBothebotAccountMutation
} = UserApiEndpoints;
