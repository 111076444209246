import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/css/loader.css";
import PreLoader from "./components/loader/preLoader";
import Home from "./components/home/main";
import Password from "./components/home/password";
import { DefaultError } from "./components/includes/DefaultError";
import { useInternet } from "./hooks/useInternet";
const Index = lazy(() => import("./components/home/index"));
/*const Home = lazy(() => import('./components/home/main'));
const Password = lazy(() => import('./components/home/password'));*/
const Address = lazy(() => import("./components/rnd/address"));
function App() {
    const { isOnline, isOpen, setIsOpen } = useInternet();
    const RedirectComponent = () => {
        useEffect(() => {
            window.location.href = process.env.REACT_APP_REDIRECT;
        }, []);

        return null;
    };
    if (process.env.REACT_APP_SHOW_PASSWORD_SCREEN == "0") {
        localStorage.setItem("boAuth", false);
    }
    return (
        <>
            <DefaultError
                heading={"You are offline."}
                message={"Please check your internet connection"}
                open={!isOnline && isOpen}
                onClick={() => {
                    setIsOpen(false);
                }}
            />
            <BrowserRouter>
                <div className="wrapper">
                    <div className="content-wrapper">
                        <Suspense
                            fallback={
                                <div>
                                    <PreLoader />
                                </div>
                            }>
                            <Routes>
                                <Route path="/:processId" element={<Index />} exact={true} />
                                <Route path="/feed" element={<Index />} exact={true} />
                                {/* <Route path="/" element={<Home />} exact={true} /> */}
                                <Route path="/" element={<RedirectComponent />} exact={true} />
                                <Route path="/feed/:orderID?/:ourID?" element={<Index />} exact={true} />
                                <Route path="/password" element={<Password />} exact={true} />
                                <Route path="/password/:processId" element={<Password />} exact={true} />
                                <Route path="/address" element={<Address />} exact={true} />
                            </Routes>
                        </Suspense>
                    </div>
                </div>
            </BrowserRouter>
        </>
    );
}

export default App;
