import { useEffect, useState } from "react";

export const useInternet = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const openState = () => {
            if (!isOpen) {
                setIsOpen(true);
            }
        };

        const id = setInterval(openState, 10000);
        return () => clearInterval(id);
    }, [isOnline, isOpen]);

    useEffect(() => {
        function onlineHandler() {
            setIsOnline(true);
        }

        function offlineHandler() {
            setIsOnline(false);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);

        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);

    return {
        isOnline,
        isOpen,
        setIsOpen
    };
};
